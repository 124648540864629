<template>
    <PageLayout>
        <template v-slot:side>
            <v-icon color="secondary" @click="filter_show===true ? filter_show=false : filter_show=true">
                mdi-filter-plus
            </v-icon>
            <v-list class="filter_left mt-5">
                <v-list-group :ripple="false" v-model="group1" :group="'group1'" class="px-0">
                    <template v-slot:activator class="px-0">
                        <v-list-item-title :ripple="false" class="main_filter">{{ $t('system_filters') }}
                        </v-list-item-title>
                    </template>

                    <v-list-item :ripple="false"  link>
                        <v-list-item-title v-text="$t('all')" class="cursor-pointer" :class="department_id === null ? 'active' : ''"
                                           @click="filterWorkDay(null)"></v-list-item-title>
                        <v-list-item-icon v-text="'-'" class="filter_count"></v-list-item-icon>
                    </v-list-item>
                    <v-list-item :ripple="false" v-for="(item, i) in system_filters" :key="i" link
                                 :class="item.id === department_id ? 'active v-list-item--active' : ''">
                        <v-list-item-title v-text="item.name" class="cursor-pointer" :class="item.id === department_id ? 'active' : ''"
                                           @click="filterWorkDay(item)"></v-list-item-title>
                        <v-list-item-icon v-text="'-'" class="filter_count"></v-list-item-icon>
                    </v-list-item>

                </v-list-group>
            </v-list>


        </template>
        <template>
            <v-form @submit.prevent="getWorkDays()">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <TabsNav/>
                    <v-row class="pt-0 pb-5">
                        <v-col cols="10"  class="mt-1">
                            <h1>{{ $t('working_days') }}</h1>
                        </v-col>
                        <v-col cols="2"
                               class="d-flex justify-center justify-sm-end align-self-center">
                            <v-btn icon color="primary" @click="saveExcel()">
                                <v-icon>mdi-microsoft-excel</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row class="pb-5" v-if="filter_show">
                        <v-col class="py-0" cols="12">
                            <v-card>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="12">
                                            <ValidationProvider ref="selectedMonth" rules="min:1"
                                                                v-slot="{ errors, valid }">

                                                <SelectInput

                                                    item-text="name"
                                                    clearable
                                                    hide-details
                                                    hide-label
                                                    :items="months"
                                                    v-model="selectedMonth"
                                                    name="columns"
                                                    id="aggregate_column"
                                                    :hideLabel="false"
                                                    :label="$t('select_month')"
                                                    @input="updateDaysInMonth"
                                                ></SelectInput>

                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="12">
                                            <ValidationProvider ref="department_id" rules="min:1"
                                                                v-slot="{ errors, valid }">

                                                <SelectInput

                                                    item-text="name"
                                                    item-value="id"
                                                    clearable
                                                    hide-details
                                                    hide-label
                                                    :items="system_filters"
                                                    v-model="department_id"
                                                    name="columns"
                                                    id="department_id"
                                                    :hideLabel="false"
                                                    :label="$t('department')"
                                                ></SelectInput>

                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12"
                                               class="d-flex justify-center justify-sm-end align-self-center">
                                            <v-btn type="submit" :disabled="invalid || loading" class="infinity_button"
                                                   :block="$vuetify.breakpoint.xsOnly" color="primary">
                                                {{ $t('search') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>

                            </v-card>
                        </v-col>
                    </v-row>
                </ValidationObserver>
            </v-form>

            <div class="mt-3">
                <div>
                    <v-data-table
                        :headers="headers"
                        :items="filteredWorkers"
                        item-value="name"
                        item-key="name"
                        class="elevation-1 rounded-0"
                        :hide-default-header="true"

                        :items-per-page="-1"
                        :loading="loading"
                        :locale="lang" :loading-text="$t('loading_please_wait')"
                    >
                        <!-- Кастомный заголовок -->
                        <template v-slot:header="{ props: { headers } }">
                            <thead>
                            <tr>
                                <th v-for="h in headers" :key="h.value" class="column-highlight" style="position: relative" :class="{ 'weekend-cell': h.is_weekend }">
                                    <span class="text-center">{{ h.week }}</span>
                                    <div v-if="h.day" style="position: absolute; bottom: 5px; right:5px">
                                        <v-avatar
                                            color="primary"
                                            rounded
                                            size="22"
                                            tile
                                        >
                                            <span class="white--text">{{ h.day }}</span>
                                        </v-avatar>
                                    </div>
                                    <div v-else>{{h.text}}</div>
                                </th>
                            </tr>
                            </thead>
                        </template>

                        <!-- Кастомное тело таблицы -->
                        <template v-slot:body="{ items }">
                            <tr v-for="(worker, index) in items" :key="worker.name" class="table-row">
                                <td class="py-2 pl-2 column-highlight-min-name">{{ worker.name }}</td>
                                <td
                                    v-for="(day, index) in daysInMonth"
                                    :key="index"
                                    class="column-highlight column-highlight-min"
                                    @contextmenu.prevent="openMenu($event, worker, day)"
                                    @click.prevent="openMenu($event, worker, day)"
                                    :class="{ 'weekend-cell': isWeekend(day) }"
                                    :style="{...isBG(worker, day)}"
                                >
                                    <div v-if="worker.days[day] && worker.days[day].work_duration" class="py-2" style="position: relative">
                                        <div v-if="worker.days[day].is_remote" style="position: absolute; top:1px; right: 2px">
                                            <v-icon color="primary" size="20" :title="$t('on_remote_site')">mdi-home</v-icon>
                                        </div>
                                        <div v-if="worker.days[day].late" style="position: absolute; top:1px; left: 2px">
                                            <v-icon color="red" size="20" :title="$t('late')">mdi-clock-time-three-outline</v-icon>
                                        </div>

                                        <div v-if="worker.days[day].end_time">
                                            {{ worker.days[day].work_duration }}
                                        </div>
                                        <div v-if="worker.days[day].end_time" class="day_work">
                                            {{ worker.days[day].start_time }} - {{ worker.days[day].end_time }}
                                        </div>
                                        <div v-else>
                                            <div>{{ worker.days[day].start_time }}</div>
                                            <div class="day_work">{{ $t('come') }}</div>
                                        </div>

                                    </div>
                                    <div v-if="worker.days[day] && worker.days[day].type_vacation" class="py-2" style="position: relative">
                                        <div>{{ worker.days[day].type_vacation }}</div>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                    <v-menu
                        v-model="showMenu"
                        :position-x="menuPosition.x"
                        :position-y="menuPosition.y"
                        absolute
                        offset-y
                    >
                        <v-list tile>
                            <v-list-item link @click="getWorkDayShow()" class="py-0 my-0" dense>
                                <v-list-item-icon class="pr-4 mr-0">
                                    <v-icon  color="primary">mdi-clipboard-account-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="font-size-unset">{{ $t('details') }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item link @click="dialogWorkDayLate()" v-if="click_day && parseInt(click_day.late) <= 0" dense>
                                <v-list-item-icon class="pr-4 mr-0">
                                    <v-icon  color="red">mdi-clock-time-three-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="font-size-unset">{{$t('late')}}</v-list-item-title>
                            </v-list-item>
                            <v-list-item link @click="setWorkDayLate()"  v-if="click_day && click_day.late && parseInt(click_day.late) > 0" dense>
                                <v-list-item-icon class="pr-4 mr-0">
                                    <v-icon  color="primary">mdi-clock-time-three-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="font-size-unset">{{$t('cancel_lateness')}}</v-list-item-title>
                            </v-list-item>
                            <v-list-item link v-if="click_day && click_day.is_remote" @click="setWorkDayRemote(click_day)" dense>
                                <v-list-item-icon class="pr-4 mr-0">
                                    <v-icon  color="red">mdi-home</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="font-size-unset">{{ $t('return_from_deleted_account') }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item link v-if="click_day && !click_day.is_remote" @click="setWorkDayRemote(click_day)" dense>
                                <v-list-item-icon class="pr-4 mr-0">
                                    <v-icon  color="primary">mdi-home</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="font-size-unset">{{ $t('on_remote_site') }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>


            <v-dialog
                transition="dialog-bottom-transition"
                max-width="700"
                v-model="dialogDetailWorkDayAdmin"
                v-if="dialogDetailWorkDayAdmin"
            >
                <v-card>
                    <v-toolbar class="dialog_toolbar" elevation="0">
                        <div class="ma-auto ml-0">
                            <span>{{ $t('details') }}</span>
                        </div>
                        <div>
                            <v-icon @click="dialogDetailWorkDayAdmin = false" :title="$t('close')">mdi-close</v-icon>
                        </div>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>


                            <v-col cols="12">
                                <v-list subheader two-line class="background_none py-0">
                                    <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                            <img v-if="admin_info.photo !== '/img/avatar.png'" :src="admin_info.photo"
                                                 :alt="admin_info.name">
                                            <v-icon v-else color="primary" size="22">
                                                ${{ 'settingsIcon' }}
                                            </v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content class="py-0 title_subtitle">
                                            <v-list-item-title v-text="admin_info.name"></v-list-item-title>
                                            <v-list-item-subtitle
                                                v-text="formatPhoneNumber(admin_info.phone)"></v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-icon class="my-0" v-if="detail_work_day.is_remote">
                                            <v-icon color="primary" size="40" :title="$t('on_remote_site')">mdi-home</v-icon>
                                        </v-list-item-icon>
                                    </v-list-item>
                                </v-list>
                            </v-col>


                            <v-col cols="12" sm="6">
                                <div>
                                    <v-avatar width="100%" height="100%">
                                        <img v-if="detail_work_day.start_photo_path !== '/img/avatar.png'"
                                             :src="detail_work_day.start_photo_path" :alt="admin_info.name">
                                        <v-icon v-else color="primary" size="155">
                                            ${{ 'settingsIcon' }}
                                        </v-icon>
                                    </v-avatar>
                                    <div class="pt-2">
                                        <div>
                                            {{ detail_work_day.start_time }}
                                        </div>
                                        <div>
                                            {{ detail_work_day.start_location }}
                                        </div>
                                        <div v-if="detail_work_day.late" style="color: red">
                                            {{ $t('late_hour', {'hour': detail_work_day.late}) }}
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <div>
                                    <v-avatar width="100%" height="100%">
                                        <img v-if="detail_work_day.end_photo_path !== '/img/avatar.png'"
                                             :src="detail_work_day.end_photo_path" :alt="admin_info.name">
                                        <v-icon v-else color="primary" size="200">
                                            ${{ 'settingsIcon' }}
                                        </v-icon>
                                    </v-avatar>
                                    <div class="pt-2">
                                        <div>
                                            {{ detail_work_day.end_time }}
                                        </div>
                                        <div>
                                            {{ detail_work_day.end_location }}
                                        </div>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12">
                                <v-data-table

                                    :headers="work_days_headers"
                                    :items="work_days"
                                    :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                    :loading="loading"
                                    :locale="lang" :loading-text="$t('loading_please_wait')"
                                    class="elevation-0"
                                >
                                    <template v-slot:header="{ props: { headers } }"
                                              v-if="(!$vuetify.breakpoint.xsOnly)">
                                        <thead>
                                        <tr>
                                            <th v-for="h in headers" :key="h.value" class="table_header">
                                                <span>{{ h.text }}</span>
                                            </th>
                                        </tr>
                                        </thead>
                                    </template>
                                </v-data-table>
                            </v-col>


                        </v-row>

                    </v-card-text>

                </v-card>
            </v-dialog>

            <v-dialog
                transition="dialog-bottom-transition"
                max-width="700"
                v-model="dialog_work_day_late"
                v-if="dialog_work_day_late"
            >
                <v-card>
                    <ValidationObserver ref="observer_close_day" v-slot="{ invalid, validated, passes, validate }">
                        <v-toolbar class="dialog_toolbar" elevation="0">
                            <div class="ma-auto ml-0">
                                <span>{{ $t('late') }}</span>
                            </div>
                            <div>
                                <v-icon @click="dialog_work_day_late = false" :title="$t('close')">mdi-close</v-icon>
                            </div>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <ValidationProvider ref="late" rules="required|min:1"
                                                        v-slot="{ errors, valid }">
                                        <SelectInput
                                            clearable
                                            item-text="name"
                                            item-value="id"
                                            hide-details
                                            hide-label
                                            :items="lateItems"
                                            v-model="late"
                                            name="late"
                                            id="late"
                                            :hideLabel="false"
                                            :label="$t('how_late_am')"
                                            :error="!valid"
                                            :error-messages="errors"
                                        ></SelectInput>

                                    </ValidationProvider>
                                </v-col>
                            </v-row>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" :disabled="invalid || loading" text @click="setWorkDayLate()">
                                {{ $t('save') }}
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>

        </template>
    </PageLayout>
</template>

<script>

import {mapGetters} from "vuex"
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import PageLayout from "@/components/Leentech/PageLayout.vue";
import TabsNav from "@/components/Leentech/TabsNav.vue";
import SelectInput from "@/components/Form/SelectInput.vue";

export default {
    name: "WorkDay",
    components: {
        PageLayout,
        ValidationProvider,
        ValidationObserver,
        TabsNav,
        SelectInput
    },
    data() {

        const months = [
            {name: this.$t('january'), value: 0},
            {name: this.$t('february'), value: 1},
            {name: this.$t('march'), value: 2},
            {name: this.$t('april'), value: 3},
            {name: this.$t('may'), value: 4},
            {name: this.$t('june'), value: 5},
            {name: this.$t('july'), value: 6},
            {name: this.$t('august'), value: 7},
            {name: this.$t('september'), value: 8},
            {name: this.$t('october'), value: 9},
            {name: this.$t('november'), value: 10},
            {name: this.$t('november'), value: 11}


        ];

        const currentMonth = new Date().getMonth();


        return {
            months,
            selectedMonth: currentMonth,
            headers: [],
            daysInMonth: [],
            workers: [],
            sortBy: "id",
            sortDir: true,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 7,
            totalVacations: 0,
            options: {},
            loading: false,
            admin: null,
            showMenu: false,
            menuPosition: {x: 0, y: 0},
            dialogDetailWorkDayAdmin: false,
            admin_info: null,
            work_days: [],
            work_days_headers: [
                {
                    text: this.$t('date'),
                    align: "left",
                    sortable: true,
                    value: "changed_at"
                },
                {
                    text: this.$t('status'),
                    align: "left",
                    sortable: true,
                    value: "status"
                },
            ],
            detail_work_day: null,
            click_day: null,
            dialog_work_day_late: false,
            lateItems: [
                {'id': 1, 'name': this.$t('hour_hour', {hour: '1'})},
                {'id': 2, 'name': this.$t('hour_hour', {hour: '2'})},
                {'id': 3, 'name': this.$t('hour_hour', {hour: '3'})},
                {'id': 4, 'name': this.$t('hour_hour', {hour: '4'})},
                {'id': 5, 'name': this.$t('hour_hour', {hour: '5'})},
                {'id': 6, 'name': this.$t('hour_hour', {hour: '6'})},
                {'id': 7, 'name': this.$t('hour_hour', {hour: '7'})},
            ],
            late: null,


            system_filters: [],
            group1: true,
            filter_show: false,
            admin_id: null,
            department_id: null,
            filteredWorkers:[]
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems']),
    },
    created() {
        this.updateDaysInMonth(); // Инициализация
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
        this.getWorkDays();
        this.getDepartments();
    },

    watch: {
        // Отслеживаем изменения в workers и admin_id
        workers: {
            handler: 'applyFilter', // вызываем метод для фильтрации
            deep: true, // учитываем вложенные изменения
        },
        admin_id: 'applyFilter', // обновляем фильтр при изменении admin_id
    },

    methods: {
        updateDaysInMonth() {
            const daysInMonthCount = new Date(new Date().getFullYear(), this.selectedMonth + 1, 0).getDate();
            this.daysInMonth = Array.from({ length: daysInMonthCount }, (_, i) => i + 1);

            // Создаем массив названий дней недели
            const daysOfWeek = [this.$t('SUN'), this.$t('MON'), this.$t('TUE'), this.$t('WED'), this.$t('THU'), this.$t('FRI'), this.$t('SAT')];

            this.headers = [
                { text: this.$t('employees_full_name'), align: 'start', value: 'name' },
                ...this.daysInMonth.map(day => {
                    // Вычисляем день недели для текущего дня
                    const dayOfWeek = new Date(new Date().getFullYear(), this.selectedMonth, day).getDay();
                    const is_weekend = dayOfWeek === 0 || dayOfWeek === 6; // Воскресенье или суббота
                    // Форматируем текст как "1 вторник", "2 среда" и т.д.
                    return { text: `${day} ${daysOfWeek[dayOfWeek]}`, value: `day-${day}`, week: `${daysOfWeek[dayOfWeek]}`, day:`${day}`, is_weekend };
                }),
            ];

        },
        openMenu(event, worker, day) {
            this.menuPosition = {x: event.clientX, y: event.clientY};
            this.showMenu = true;
            this.selectedWorker = worker;
            this.click_day = this.selectedWorker.days[day]
            this.selectedDay = day;
        },
        isWeekend(day) {
            const year = new Date().getFullYear(); // Получаем текущий год
            const dayOfWeek = new Date(year, this.selectedMonth, day).getDay(); // Определяем день недели
            return dayOfWeek === 0 || dayOfWeek === 6; // Возвращаем true для субботы или воскресенья
        },
        isBG(worker, day) {
            if (worker.days && worker.days[day] && worker.days[day].color){
                return {
                    backgroundColor:  worker.days[day].color
                };
            }

        },
        async getWorkDays() {
            this.loading = true
            let params = {}
            params.month = this.selectedMonth

            if(this.department_id){
                params.department = this.department_id
            }

            //params.year = 2024
            await this.$http
                .get("admin/work_day_all", {
                    params: params,
                })
                .then(res => {
                    this.workers = res.body;
                })
                .catch(err => {
                    this.workers = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        dialogWorkDayLate() {
            this.dialog_work_day_late = true
        },
        async setWorkDayLate() {


            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }

            if(this.late){
                formData.append('late', this.late)
            }
            await this.$http
                .put(`admin/set_work_day_late/${this.click_day.uuid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('work_day_has_been_changed'));
                    if (res && res.body && res.body.data && res.body.data.work_duration) {
                        this.click_day.work_duration = res.body.data.work_duration ;
                        this.click_day.late = res.body.data.late > 0 ? res.body.data.late : 0 ;
                    }
                    this.late = null;
                    this.dialog_work_day_late = false;


                })
                .catch(err => {
                    this.$toastr.error(this.$t('work_day_has_not_been_changed'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false

                })

        },
        async getWorkDayShow() {
            this.loading = true
            let params = {}
            //params.year = 2024
            params.month = this.selectedMonth
            params.day = this.selectedDay
            params.admin_id = this.selectedWorker.admin_id
            await this.$http
                .get("admin/work_day_user", {
                    params: params,
                })
                .then(res => {
                    if (res.body.data && res.body.data.admin) {
                        this.admin_info = res.body.data.admin;
                        this.detail_work_day = res.body.data;
                        this.work_days = res.body.data.work_days;
                        this.dialogDetailWorkDayAdmin = true
                    }
                })
                .catch(err => {
                    this.workers = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async setWorkDayRemote(item) {


            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }

            if (!item.is_remote) {
                formData.append('is_remote', 1)
            } else {
                formData.append('is_remote', 0)
            }


            //  this.work_day_type = 'start';
            await this.$http
                .put(`admin/set_work_day_remote/${item.uuid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('work_day_has_been_changed'));
                    if (res && res.body && res.body.data) {
                        this.click_day.is_remote = res.body.data.is_remote ? 1 : 0;
                    }


                })
                .catch(err => {
                    this.$toastr.error(this.$t('work_day_has_not_been_changed'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false

                })

        },

        async getDepartments() {
            let params = {};
            await this.$http
                .get(`admin/department`, {
                    params: params,
                })
                .then(res => {
                    this.system_filters = res.body.data
                })
                .catch(err => {
                    this.system_filters = [];
                    this.$toastr.error(this.$t('failed_to_get_list_departments'))
                });
        },

        filterWorkDay(item){
            this.department_id = item && item.id ? item.id :  null
            this.getWorkDays();
        },
        applyFilter() {
            if (!this.admin_id) {
                this.filteredWorkers = this.workers; // если admin_id нет, берем все данные
            } else {
                this.filteredWorkers = this.workers
                    .map(worker => ({
                        ...worker,
                        days: Object.fromEntries(
                            Object.entries(worker.days || {}).filter(([day, details]) => {
                                // Проверяем, что details не null и admin_id совпадает
                                return details && details.admin_id === this.admin_id;
                            })
                        ),
                    }))
                    .filter(worker => Object.keys(worker.days).length > 0); // Исключаем работников без доступных дней
            }
        },

        async saveExcel() {
            var _this = this;
            this.loading = true
            let params = {}
            params.month = this.selectedMonth

            if(this.department_id){
                params.department = this.department_id
            }

            //params.year = 2024
            await this.$http
                .get("admin/work_day_all/excel", {
                    params: params,
                })
                .then(res => {
                    const url = res.body.data.url;
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = res.body.data.name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(err => {
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.loading = false
                })
        },
    }
};
</script>

<style scoped>
/* Вертикальные полоски для тела таблицы */
.column-highlight {
    border-right: 1px solid #e0e0e0; /* Полоска между колонками */
    background-color: #ffffff;
    text-align: center !important;
}

.column-highlight-min {
    min-width: 130px;
}

.column-highlight-min-name {
    min-width: 180px;
}

/* Убираем полоску справа для последней колонки */
tr > td:last-child,
tr > th:last-child {
    border-right: none;
}

/* Выравнивание заголовков */
th {
    text-align: center;
    padding: 8px;
    font-weight: bold;
}

.table-row {
    min-height: 50px !important;
}

.table-row > td {
    border-bottom: 1px solid #e0e0e0 !important;
    min-height: 50px !important;
}

.day_work {
    color: #B3B3B3;
}
.font-size-unset{
    font-size: unset
}
.weekend-cell {
    background-color: #fff6f9;
}
</style>
